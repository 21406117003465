<template>
  <v-edit-dialog
    :return-value.sync="item"
    v-if="!statusAprovado(item) && (datasValidasEdicao(item) || item.periodicidade === 'MENSAL')"
    persistent
    :ref="`${prefixRefNameVBDefinido}${item.idApuracao}`"
    @open="guardarValorInicial(item)"
  >
    <div class="mt-2">
      {{ getMoney(item.valorBase) }}
      <v-icon class="ml-2" size="19">edit</v-icon>
    </div>
    <template v-slot:input>
      <div class="mt-4 text-h6">
        {{ $t('title.editar')}}
      </div>
      <span class="grey--text caption">
        {{ `${$tc('label.valor_original')} ${formatarValor(item.valorBaseOriginal)}`}}
      </span>
      <div class="mt-4" @keydown.esc="closeDialogModal(item)">
        <v-form lazy-validation ref="form">
          <input-money
            :label="$tc('label.novo_valor_base')"
            v-model="item.valorBase"
            :min="0"
            persistent
          />
          <v-text-field
            :label="`${$t('label.justificativa')} *`"
            v-model="item.justificativaAlterarValorBase"
            type="text"
            :rules="[rules.required]"
          >
          </v-text-field>
        </v-form>
      </div>
      <div class="ma-3">
        <v-btn @click="cancelar(item)" text color="grey"> {{ $t('label.cancelar') }} </v-btn>
        <v-btn @click="salvarValorAlterado(item, true)" :disabled="desabilitaSalvar(item)"
            text color="primary"> {{ $t('label.salvar') }}
        </v-btn>
      </div>
    </template>
    <span v-if="!!indValorBaseDefinidoAlterado(item)" class="grey--text caption">
      {{ getMoney(item.valorBaseOriginal) }}
    </span>
  </v-edit-dialog>
  <v-edit-dialog
    :return-value.sync="item"
    v-else-if="!!indValorBaseDefinidoAlterado(item) && statusAprovado(item)"
    :ref="`${prefixRefNameVBDefinido}${item.idApuracao}`"
  >
    <div class="mt-2">
      {{ getMoney(item.valorBase) }}
      <v-icon class="ml-2">description</v-icon>
    </div>
    <template v-slot:input>
      <div class="mt-4">
        <input-money
          :label="$tc('label.valor_original')"
          v-model="item.valorBaseOriginal"
          disabled
          persistent
        />
        <v-row>
          <v-col cols="12" md="10" sm="10">
            <v-text-field
              :label="$tc('label.justificativa')"
              v-model.trim="item.justificativaAlterarValorBase"
              type="text"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col v-if="habilitaTooltipJustificativa(item)" cols="12" md="1" sm="1" class="mt-5">
            <template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">info</v-icon>
                </template>
                <template v-slot:default>
                {{ item.justificativaAlterarValorBase }}
                </template>
              </v-tooltip>
            </template>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-edit-dialog>
  <td v-else-if="!statusAprovado(item) && !datasValidasEdicao(item)">
    <tr>
      <td>
        {{ getMoney(item.valorBase) }}
          <template v-if="habilitaTooltipJustificativa(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">info</v-icon>
              </template>
              <template v-slot:default>
              {{ item.justificativaAlterarValorBase }}
              </template>
            </v-tooltip>
          </template>
      </td>
    </tr>
    <tr>
      <td class="grey--text caption">
        <span v-if="!!indValorBaseDefinidoAlterado(item)" class="grey--text caption">
          {{ getMoney(item.valorBaseAlterado) }}
        </span>
      </td>
    </tr>
  </td>
  <td v-else>
    {{ getMoney(item.valorBase) }}
  </td>
</template>

<script>

import { getMoney } from '@/common/functions/helpers';
import InputMoney from '@/shared-components/inputs/InputMoney';

export default {
  name: 'DialogEditValorBaseDefinido',
  components: {
    InputMoney,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    salvarValorAlterado: {
      type: Function,
      default: () => ({}),
    },
    cancelarEdicaoValorBase: {
      type: Function,
      default: () => ({}),
    },
    indValorBaseDefinidoAlterado: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      prefixRefNameVBDefinido: 'modalVBDefinido-',
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
      },
      valorBase: null,
      justificativa: '',
    };
  },
  methods: {
    getMoney,
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    datasValidasEdicao(item) {
      return item.dataParcialFormatada && item.dataParcialFormatada.resultado;
    },
    desabilitaSalvar(item) {
      return !item.valorBase || !item.justificativaAlterarValorBase || item.valorBase < 0;
    },
    habilitaTooltipJustificativa(item) {
      return item.justificativaAlterarValorBase && item.justificativaAlterarValorBase.length > 10;
    },
    indCrescimento(item) {
      return item.indMetaIntervalo;
    },
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    cancelar(item) {
      item.justificativaAlterarValorBase = this.justificativa;
      item.valorBase = this.valorBase;
      const modalRef = this.prefixRefNameVBDefinido.concat(item.idApuracao);
      this.$refs[modalRef].isActive = false;
      this.$emit('DialogEditValorBase__isEditandoValorBase', false);
    },
    guardarValorInicial(item) {
      this.valorBase = item.valorBase;
      this.justificativa = item.justificativaAlterarValorBase;
      this.$emit('DialogEditValorBase__isEditandoValorBase', true);
    },
    closeDialogModal(item) {
      const modalRef = this.prefixRefNameVBDefinido.concat(item.idApuracao);
      this.$refs[modalRef].isActive = false;
      this.$emit('DialogEditValorBase__isEditandoValorBase', false);
    },
    verificaValorCrescimento(item) {
      if (item.indMetaIntervalo && item.ultimaFaixa && !item.vlrNdGerado) {
        return getMoney(item.valorBase);
      }
      item.valorBase = 0;
      return getMoney(item.valorBase);
    },
  },
};
</script>
