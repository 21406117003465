<template>
  <v-card style="width: 95%;" class="ml-8">
    <v-card-title class="py-2">
      <v-row>
        <v-col cols="12" sm="3">
          <div class="card-title" style="font-size: 16px;">
              {{ `${$t('label.periodo')}* :`}}
              {{ labelData }}
            <v-menu
              v-model="menuPeriodo"
              :nudge-width="500"
              offset-x
              :close-on-content-click="false"
              :close-on-click="false"
              @input="handleMenuPeriodo"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon v-on="on"
                  v-bind="attrs"
                >
                <v-icon size="20" class="mb-1">edit</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>{{ $t('label.editar') }}</v-card-title>
                <v-row dense>
                  <v-col cols="12" md="4" class="ml-2">
                        <v-menu
                          ref="dta_inicio"
                          :close-on-content-click="false"
                          v-model="menuDataInicio"
                          id="dta_inicio"
                          name="dta_inicio"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              ref="dataInicio"
                              class="Form-text__invalid"
                              required
                              :return-masked-value="true"
                              v-model="dataInicioFormatada"
                              :label="`${$t('label.data_inicio')} *`"
                              prepend-icon="event">
                            </v-text-field>
                          </template>
                          <v-date-picker
                            locale="pt-br"
                            color="primary"
                            :show-current="dataInicioPeriodo"
                            v-model="dataInicioPicker"
                            :type="'month'"
                            @input="menuDataInicio = false">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu
                          ref="dta_fim"
                          :close-on-content-click="false"
                          v-model="menuDataFim"
                          id="dta_fim"
                          name="dta_fim"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              ref="dta_fim"
                              class="Form-text__invalid"
                              required
                              :return-masked-value="true"
                              v-model="dataFimFormatada"
                              :label="`${$t('label.fim')} *`"
                              prepend-icon="event">
                            </v-text-field>
                          </template>
                          <v-date-picker
                            locale="pt-br"
                            color="primary"
                            :min="filtro.dataInicio ? filtro.dataInicio : dataInicioPeriodo"
                            :show-current="filtro.dataInicio"
                            v-model="dataFimPicker"
                            :type="'month'"
                            @input="menuDataFim = false">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                </v-row>
                <v-col cols="12" class="pt-0">
                  <span v-show="!vigenciaValida" class="mensagem-vigencia">
                    {{ $t('message.data_vigencia_limite') }}
                  </span>
                </v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    @click="handleMenuPeriodo(false, true)"
                  >
                  {{ $t('label.cancelar') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    :disabled="!vigenciaValida"
                    @click="formatarLabelData()"
                  >
                  {{ $t('label.salvar') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="card-title" style="font-size: 16px;">
            {{ `${$t('label.tipo_acao')}* :`}}
            {{ labelTipoAcao }}
            <v-menu
              v-model="menuTipoAcao"
              :nudge-width="400"
              :close-on-content-click="false"
              :close-on-click="false"
              offset-x
              @input="handleMenuTipoAcao"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon v-on="on"
                  v-bind="attrs"
                >
                <v-icon size="20" class="mb-1">edit</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>{{ $t('label.editar') }}</v-card-title>
                <v-row dense>
                  <v-col cols="12" md="11" class="ml-2">
                    <v-autocomplete
                        v-model="filtro.tipoAcao"
                        :items="itemsTipoAcao"
                        :label="`${$tc('label.tipo_acao', 1)}`"
                        item-text="nome"
                        item-value="id"
                        required
                      ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    @click="cancelarTipoAcao()"
                  >
                  {{ $t('label.cancelar') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="salvarTipoAcao()"
                  >
                  {{ $t('label.salvar') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="card-title" style="font-size: 16px;">
            {{ `${$tc('label.categoria', 1)}* :`}}
            {{ labelCategoria }}
            <v-menu
              v-model="menuCategoria"
              :nudge-width="500"
              :close-on-content-click="false"
              :close-on-click="false"
              offset-x
              @input="handleMenuCategoria"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon v-on="on"
                  v-bind="attrs"
                >
                <v-icon size="20" class="mb-1">edit</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>{{ $t('label.editar') }}</v-card-title>
                <v-row dense>
                  <v-col cols="12" md="10" class="ml-2">
                    <v-autocomplete
                      id="combo-categorias"
                      v-model="filtro.categoria"
                      :items="itemsCategoria"
                      chips
                      deletable-chips
                      multiple
                      autocomplete="off"
                      return-object
                      item-text="codNome"
                      item-value="id"
                      :item-disabled="isCategoriaDisabled"
                      :label="$tc('label.categoria', 2)"
                      @change="handleCategoriaChange">
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    @click="cancelarCategoria()"
                  >
                  {{ $t('label.cancelar') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="salvarCategoria()"
                  >
                  {{ $t('label.salvar') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </v-col>
        <v-col v-if="habilitaTooltipCategoria" cols="12" md="1" sm="4" class="mb-5">
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">info</v-icon>
              </template>
              <template v-slot:default>
                <ul>
                  <li v-for="categoria in filtro.categoria" :key="categoria.id">{{ categoria.nomeCategoria }}</li>
                </ul>
              </template>
            </v-tooltip>
          </template>
        </v-col>
        <v-col cols="12" md="1" sm="4" class="mb-5">
          <v-btn
            small
            right
            absolute
            color="primary"
            @click="aplicarFiltrosObrigatorios"
            :disabled="desabilitaFiltrar || menuAberto">
            {{$t('label.aplicar')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>

import moment from 'moment';
import {
  parseDateYYYYMMDD,
  formatDateMMYYYY,
} from '@/common/functions/date-utils';

export default {
  name: 'ApuracaoContratoFiltrosObrigatorios',
  components: {
  },
  props: {
    idContratoTipo: {
      type: Number,
    },
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      menuPeriodo: false,
      menuTipoAcao: false,
      menuCategoria: false,
      itemsTipoAcao: [],
      itemsCategoria: [],
      definicaoDtaFim: {
        labelCampo: 'fim',
        nomCampoId: 'dtaFim',
        tipoCampo: 'DATA',
        tipoSelecao: 'month',
        vlrObrigatorio: true,
      },
      definicaoDtaInicio: {
        labelCampo: 'inicio',
        nomCampoId: 'dtaInicio',
        tipoCampo: 'DATA',
        tipoSelecao: 'month',
        vlrObrigatorio: true,
      },
      dtaInicio: null,
      dtaFim: '',
      menuDataInicio: false,
      menuDataFim: false,
      dataInicioPeriodo: this.moment().format('MMMM-YYYY'),
      filtro: {
        dataInicio: this.moment().subtract(1, 'months').format('YYYY-MM'),
        dataFim: this.moment().subtract(1, 'months').format('YYYY-MM'),
        tipoAcao: null,
        categoria: [],
      },
      labelData: '',
      labelTipoAcao: '',
      labelCategoria: '',
      disableCategorias: false,
      habilitaTooltipCategoria: false,
      listaCategoriasTooltip: [],
      vigenciaValida: true,
      menuAberto: false,
    };
  },
  watch: {
    dataInicioFormatada: {
      handler() {
        this.verificarVigencia();
      },
    },
    dataFimFormatada: {
      handler() {
        this.verificarVigencia();
      },
    },
  },
  computed: {
    isCategoriaDisabled() {
      return (categoria) => this.disableCategorias && categoria.id !== 0;
    },
    dataInicioFormatada: {
      get() {
        return formatDateMMYYYY(this.filtro.dataInicio);
      },
      set(newValue) {
        this.filtro.dataInicio = parseDateYYYYMMDD(newValue);
      },
    },
    dataFimFormatada: {
      get() {
        return formatDateMMYYYY(this.filtro.dataFim);
      },
      set(newValue) {
        this.filtro.dataFim = parseDateYYYYMMDD(newValue);
      },
    },
    dataInicioPicker: {
      get() {
        return this.filtro.dataInicio ? this.filtro.dataInicio : this.dataInicioPeriodo;
      },
      set(newValue) {
        this.filtro.dataInicio = newValue;
        if (this.isAfter(this.filtro.dataInicio, this.filtro.dataFim)) {
          this.filtro.dataFim = '';
        }
      },
    },
    dataFimPicker: {
      get() {
        return this.filtro.dataFim ? this.filtro.dataFim : this.filtro.dataInicio;
      },
      set(newValue) {
        this.filtro.dataFim = newValue;
      },
    },
    desabilitaFiltrar() {
      return !this.filtro.dataInicio || !this.filtro.dataFim || !this.filtro.tipoAcao || !this.filtro.categoria || !this.filtro.categoria.length;
    },
  },
  methods: {
    carregarFiltros() {
      Promise.all([
        this.buscarTipoAcaoContrato(),
        this.buscarCategorias(),
      ]).then(() => {
        // this.carregarWorkspaceFiltros();
      });
    },
    handleCategoriaChange(selectedCategorias) {
      if (selectedCategorias.length > 0 && selectedCategorias.find((categoria) => categoria.id === 0)) {
        this.filtro.categoria = [selectedCategorias.find((categoria) => categoria.id === 0)];
        selectedCategorias = [selectedCategorias.find((categoria) => categoria.id === 0)];
        this.disableCategorias = true;
        this.habilitaTooltipCategoria = false;
      } else {
        this.disableCategorias = false;
      }
    },
    verificarVigencia() {
      const diffMonths = moment(this.filtro.dataFim).diff(this.filtro.dataInicio, 'months');
      this.vigenciaValida = diffMonths <= 12;
    },
    formatarLabelData() {
      const dataInicioFormatada = moment(this.filtro.dataInicio, 'YYYY-MM').format('MMMM/YY').toUpperCase();
      const dataFimFormatada = moment(this.filtro.dataFim, 'YYYY-MM').format('MMMM/YY').toUpperCase();
      if (this.filtro.dataInicio !== this.filtro.dataFim) {
        this.labelData = `${dataInicioFormatada} a ${dataFimFormatada}`;
      } else {
        this.labelData = dataInicioFormatada.toUpperCase();
      }
      this.menu = false;
      this.menuPeriodo = false;
      this.menuAberto = false;
      this.verificaFiltrosObrigatorios();
    },
    isAfter(data, outra) {
      return moment(data, this.formatoSalvar).isAfter(moment(outra, this.formatoSalvar));
    },
    buscarTipoAcaoContrato() {
      this.tipoAcaoResource.buscarConfiguradasEmTipoContrato({ idContratoTipo: this.idContratoTipo })
        .then((res) => {
          res.data.forEach((el) => {
            const item = { id: el.id, nome: el.nome };
            this.itemsTipoAcao.push(item);
          });
        });
    },
    buscarCategorias() {
      if (this.itemsCategoria.length === 0) {
        return this.apuracaoAcaoListagemResource.buscarTodasCategorias({ idContratoTipo: this.idContratoTipo })
          .then((res) => {
            this.itemsCategoria = res.data;
            const todos = { id: 0, codNome: 'TODAS', nomeCategoria: 'TODAS' };
            this.itemsCategoria.unshift(todos);
          });
      }
      return Promise.resolve();
    },
    salvarTipoAcao() {
      if (this.filtro.tipoAcao) {
        this.labelTipoAcao = this.itemsTipoAcao.filter((el) => el.id === this.filtro.tipoAcao)[0].nome;
      } else {
        this.labelTipoAcao = '';
      }
      this.menuTipoAcao = false;
      this.menuAberto = false;
      this.verificaFiltrosObrigatorios();
    },
    salvarCategoria() {
      let labelcat = '';
      const todasCategorias = this.filtro.categoria.find((categoria) => categoria.nomeCategoria === 'TODAS');
      if (this.filtro.categoria.length !== 0) {
        this.filtro.categoria.slice(0, 3).forEach((cat, index) => {
          labelcat += cat.nomeCategoria;
          if (index !== this.filtro.categoria.slice(0, 3).length - 1) {
            labelcat += ', ';
          }
        });
        if (this.filtro.categoria.length > 3) {
          labelcat += '...';
        }
        if (!todasCategorias && this.filtro.categoria && this.filtro.categoria.length >= 4) {
          this.habilitaTooltipCategoria = true;
        } else {
          this.habilitaTooltipCategoria = false;
        }
      } else {
        this.habilitaTooltipCategoria = false;
        labelcat = '';
      }
      this.labelCategoria = labelcat;
      this.menuCategoria = false;
      this.menuAberto = false;
      this.verificaFiltrosObrigatorios();
    },
    cancelarTipoAcao() {
      this.menuTipoAcao = false;
      this.filtro.tipoAcao = null;
      this.labelTipoAcao = '';
      this.verificaFiltrosObrigatorios();
    },
    cancelarCategoria() {
      this.habilitaTooltipCategoria = false;
      this.menuCategoria = false;
      this.filtro.categoria = null;
      this.labelCategoria = '';
      this.verificaFiltrosObrigatorios();
    },
    verificaFiltrosObrigatorios() {
      if (this.filtro.categoria && this.filtro.categoria.length && this.filtro.categoria.find((categoria) => categoria.id === 0)) {
        this.filtro.todasCategorias = this.itemsCategoria;
      }
      if ((this.filtro.categoria && this.filtro.categoria.length) && this.filtro.tipoAcao) {
        this.$emit('ApuracaoContratoFiltrosObrigatorios__FiltrosObrigatoriosPreenchidos', this.filtro);
      } else {
        this.$emit('ApuracaoContratoFiltrosObrigatorios__FiltrosObrigatoriosPreenchidos', false);
      }
    },
    aplicarFiltrosObrigatorios() {
      this.$emit('ApuracaoContratoFiltrosObrigatorios__AplicaFiltros');
    },
    handleMenuTipoAcao(val) {
      this.menuTipoAcao = val;
      if (val) {
        this.menuCategoria = false;
        this.menuPeriodo = false;
      }
      this.menuAberto = true;
    },
    handleMenuCategoria(val) {
      this.menuCategoria = val;
      if (val) {
        this.menuTipoAcao = false;
        this.menuPeriodo = false;
      }
      this.menuAberto = true;
    },
    handleMenuPeriodo(val, val2 = false) {
      if ((!val && !this.vigenciaValida) || val2) {
        this.filtro.dataInicio = this.moment().subtract(1, 'months').format('YYYY-MM');
        this.filtro.dataFim = this.moment().subtract(1, 'months').format('YYYY-MM');
        this.formatarLabelData();
      }
      this.menuPeriodo = val;
      if (val) {
        this.menuTipoAcao = false;
        this.menuCategoria = false;
      }
      this.menuAberto = true;
    },
  },

  mounted() {
    this.formatarLabelData();
    this.carregarFiltros();
  },
};
</script>
<style lang="scss">
 .my-custom-dialog {
   align-self: flex-start;
 }
 .disabled-categoria {
  color: #999999;
  cursor: not-allowed;
}
.mensagem-vigencia {
  color: red;
  margin-top: 4px;
}
</style>
