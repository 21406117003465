<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-form ref="formJustificativa" v-model="valido" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline pl-3">{{ $tc('title.finalizar_apuracoes') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row v-if="apuracaoInconsistentesParciais.length">
              <v-col cols="12" sm="12">
                <span class="font-weight-medium">{{ `${$tc('label.atencao').toUpperCase()}: ` }}</span>
                <span>{{ $tc('message.finalizar_apuracao_com_inconsistencias_parciais') }}</span>
                <span v-html="apuracoesValorRecebimentoInconsistentesFormatadas"/>
              </v-col>
            </v-row>
            <v-row v-if="apuracoesInconsistentes.length">
              <v-col cols="12" sm="12">
                <span class="font-weight-medium">{{ `${$tc('label.atencao').toUpperCase()}: ` }}</span>
                <span>{{ $tc('message.finalizar_apuracao_inconsistente') }}</span>
                <span v-html="apuracoesInconsistentesFormatadas"/>
              </v-col>
            </v-row>
            <v-row v-show="indApuracoesSelecionadas">
              <v-col cols="12" sm="12">
                <span v-html="`${$t('message.preencha_informacoes_apuracoes')}`"></span>
              </v-col>
              <v-col cols="12" sm="12">
                <v-menu
                  ref="data_contabilizacao"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      id="data_contabilizacao"
                      class="CalendarioForm-data__text"
                      readonly
                      clearable
                      :rules="[rules.required]"
                      v-model="dataContabilizacaoFormatada"
                      :label="`${$t('label.data_contabilizacao')} *`"
                      prepend-icon="event">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    color="primary"
                    scrollable
                    @input="setaDataFormatada()"
                    v-model="dataContabilizacao">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  id="fechamento_apuracao_condition"
                  index="1"
                  ref="textareaCondition"
                  name="FechamentoApuracao_condition"
                  v-model="condition"
                  auto-grow
                  autofocus
                  rows="1"
                  :rules="[rules.required, rules.requiredAlphanumeric]"
                  :label="`${$t('label.condition')} *`"
                  :counter="255"
                  maxlength="255">
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  id="fechamento_apuracao-fornecedorpagador"
                  class="custom-autocomplete"
                  v-model="fornecedorPagador"
                  return-object
                  :rules="rules.requiredFunctionOpcional('fornecedorPagador', 'codNomeCnpj', false)"
                  :items="fornecedoresPagadores"
                  :label="`${$t('label.cnpj')}`"
                  item-text="codNomeCnpj"
                  item-value="id"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :placeholder="$tc('message.digite_para_pesquisar', 1)"
                  :disabled="desabilitaFornecedorPagador"
                  :required="true"
                  :clearable="true"
                  @click:append="() => triggerSelecao('fechamento-autocomplete-fornecedorpagador')"
                  @click.native="(i) => buscaAutocomplete(i, buscaFornecedoresPagadores)"
                  :search-input.sync="triggerFornecedorPagador">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  id="fechamento_apuracao_observacao"
                  index="1"
                  ref="textareaObservacao"
                  name="FechamentoApuracao_Observacao"
                  v-model="observacaoFinalizacao"
                  auto-grow
                  autofocus
                  rows="1"
                  :label="`${$tc('label.observacao', 1)}`"
                  :counter="255"
                  maxlength="255">
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <span>{{ $tc('message.finalizar_apuracao_lote') }}</span>
              </v-col>
            </v-row>
            <v-row v-show="!indApuracoesSelecionadas">
              <v-col cols="12" sm="12">
                <span>{{ $tc('message.apuracao_nao_pode_ser_finalizada') }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="indApuracoesSelecionadas" color="primary" text @click="close">{{ $t('label.nao') }}</v-btn>
          <v-btn v-else color="primary" text @click="close">{{ $t('label.ok') }}</v-btn>
          <v-btn color="primary" text v-if="indApuracoesSelecionadas"
                 @click="afirmativo()">{{ textoBotaoAfirmativo ? textoBotaoAfirmativo : $t('label.sim') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { buscaAutocomplete, triggerSelecao } from '@/common/functions/autocomplete-utils';

export default {
  name: 'DialogFinalizarApuracoes',
  props: {
    title: '',
    textoBotaoAfirmativo: null,
    apuracoesInconsistentes: {
      type: Array,
      default: [],
    },
    apuracaoInconsistentesParciais: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      valido: false,
      dialog: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredAlphanumeric: (value) => !!/^[a-zA-Z0-9 _-]+$/.test(value) || this.$t('message.caracteres_invalidos'),
      },
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      dataContabilizacaoFormatada: null,
      dataContabilizacao: moment().format('YYYY-MM-DD'),
      apuracoesSelecionadas: [],
      fornecedoresPagadores: [],
      fornecedorPagador: {},
      condition: '',
      observacaoFinalizacao: '',
      triggerFornecedorPagador: null,
    };
  },
  computed: {
    apuracoesInconsistentesFormatadas() {
      const textoPorApuracao = [];
      textoPorApuracao.push('<ul>');
      this.apuracoesInconsistentes.forEach((a) => {
        textoPorApuracao.push(`<li>${a.idContrato} - ${a.idAcao} - ${a.idApuracao}</li>`);
      });
      textoPorApuracao.push('</ul>');

      return this.apuracoesInconsistentes.length ? textoPorApuracao.join('') : this.$t('message.nenhuma_apuracao_identificada');
    },
    apuracoesValorRecebimentoInconsistentesFormatadas() {
      const textoPorApuracao = [];
      textoPorApuracao.push('<ul>');
      this.apuracaoInconsistentesParciais.forEach((a) => {
        textoPorApuracao.push(`<li>${a.idContrato} - ${a.idAcao} - ${a.idApuracao}</li>`);
      });
      textoPorApuracao.push('</ul>');

      return this.apuracaoInconsistentesParciais.length ? textoPorApuracao.join('') : this.$t('message.nenhuma_apuracao_identificada');
    },
    indApuracoesSelecionadas() {
      return this.apuracoesSelecionadas && this.apuracoesSelecionadas.length;
    },
    desabilitaFornecedorPagador() {
      return this.fornecedoresPagadores.length === 1;
    },
  },
  watch: {
    triggerFornecedorPagador(val) {
      const { fornecedorPagador } = this;
      if (fornecedorPagador && fornecedorPagador.codNomeCnpj === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaFornecedoresPagadores(val);
      }, 500);
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    open(apuracoesSelecionadas) {
      this.apuracoesSelecionadas = apuracoesSelecionadas;
      this.dialog = true;
      setTimeout(() => this.$refs.textareaCondition.focus(), 5E2);
      this.buscaFornecedoresPagadores().then(() => {
        if (this.fornecedoresPagadores.length === 1) {
          const unico = this.fornecedoresPagadores[0];
          this.fornecedorPagador = unico;
        } else {
          this.fornecedorPagador = {};
        }
      });
    },
    close() {
      this.observacaoFinalizacao = '';
      this.condition = '';
      this.fornecedorPagador = {};
      this.dialog = false;
      this.dataContabilizacaoFormatada = null;
      this.apuracoesSelecionadas = [];
      this.$emit('cancelar');
    },
    salvarDataContabilizacao() {
      if (this.dataContabilizacao) {
        const {
          dataContabilizacao,
          condition,
          observacaoFinalizacao,
          fornecedorPagador,
        } = this;
        this.apuracoesSelecionadas.forEach((a) => {
          a.dataContabilizacao = dataContabilizacao;
          a.condition = condition;
          a.observacaoFinalizacao = observacaoFinalizacao;
          a.idFornecedor = fornecedorPagador ? fornecedorPagador.id : null;
        });
      }
      this.$emit('agree');
      this.dialog = false;
    },
    setaDataFormatada() {
      this.dataContabilizacaoFormatada = this.retornaDataFormatada(this.dataContabilizacao);
    },
    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    validarForm() {
      return this.$refs.formJustificativa.validate();
    },
    async afirmativo() {
      if (!this.validarForm()) {
        return;
      }
      await this.salvarDataContabilizacao();
    },
    buscaFornecedoresPagadores(autocomplete) {
      const parametros = {
        autocomplete,
        isSamsung: true,
        pagador: true,
      };
      if (this.apuracoesSelecionadas.length === 1) {
        parametros.id_contrato = this.apuracoesSelecionadas[0].id_contrato;
      }
      return this.planejamentoAcaoResource.buscarFornecedoresPagadores(parametros)
        .then((res) => {
          this.fornecedoresPagadores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
  },
};
</script>
