import moment from 'moment';
import {
  getMoney, getPercent, getNumber, copyObject,
} from '@/common/functions/helpers';
import { numberBetweenValues } from '@/common/functions/numeric';
import { buscarDetalhesApuracaoContrato } from '@/common/resources/apuracao-contrato';
import { executarExportacao, verificarDadosExportacao } from '@/common/resources/solicitacao-exportacao-assincrona-realizado';
import { cancelarApuracao } from '@/common/resources/apuracao-acao-edicao';
import {
  formatlastDayOfTheMonth,
} from '@/common/functions/date-utils';
import exportacao from '@/common/functions/exportacao';
import InputMoney from '@/shared-components/inputs/InputMoney';
import PopoverGenerico from '@/shared-components/PopoverGenerico';
import DialogJustificativa from '@/shared-components/dialog/DialogJustificativa';
import ApuracaoContratoTabelaFornecedor from '@/spa/apuracao-contrato-samsung/ApuracaoContratoTabelaFornecedor';
import ModalVisualizarPeriodoCalculado from '@/spa/apuracao-contrato-samsung/modals/ModalVisualizarPeriodoCalculado';
import DialogApuracaoContratoMetaIntervalo from '@/spa/apuracao-contrato-samsung/modals/DialogApuracaoContratoMetaIntervalo';
import DialogApuracaoContratoNdsGeradas from '@/spa/apuracao-contrato-samsung/modals/DialogApuracaoContratoNdsGeradas';
import DialogRealizadoDetalhamento from '@/spa/apuracao-contrato-samsung/modals/DialogRealizadoDetalhamento';
import DialogEditRecebimentoDefinido from '@/spa/apuracao-contrato-samsung/modals/DialogEditRecebimentoDefinido';
import DialogEditTipoUso from '@/spa/apuracao-contrato-samsung/modals/DialogEditTipoUso';
import DialogEditValorBase from '@/spa/apuracao-contrato-samsung/modals/DialogEditValorBase';
import DialogApuracaoContratoCancelamento from '@/spa/apuracao-contrato-samsung/modals/DialogApuracaoContratoCancelamento';
import DialogFinalizarApuracoes from '@/spa/apuracao-contrato-samsung/modals/DialogFinalizarApuracoes';
import DialogIntervaloMetaCumulativa from '@/spa/apuracao-contrato-samsung/modals/DialogIntervaloMetaCumulativa';
import DialogVerbaIntervaloMetaCumulativa from '@/spa/apuracao-contrato-samsung/modals/DialogVerbaIntervaloMetaCumulativa';
import PlanejamentoContratoModalSelecaoProduto from '../planejamento-contrato/form/passo-acoes-carta-parceria/PlanejamentoContratoModalSelecaoProduto';
import PlanejamentoContratoForm from '../planejamento-contrato/form/PlanejamentoContratoForm';

export default {
  components: {
    PlanejamentoContratoForm,
    ApuracaoContratoTabelaFornecedor,
    DialogJustificativa,
    DialogApuracaoContratoMetaIntervalo,
    DialogApuracaoContratoNdsGeradas,
    DialogRealizadoDetalhamento,
    DialogEditRecebimentoDefinido,
    DialogEditTipoUso,
    DialogEditValorBase,
    DialogApuracaoContratoCancelamento,
    DialogFinalizarApuracoes,
    DialogIntervaloMetaCumulativa,
    DialogVerbaIntervaloMetaCumulativa,
    InputMoney,
    ModalVisualizarPeriodoCalculado,
    PopoverGenerico,
    PlanejamentoContratoModalSelecaoProduto,
  },
  data() {
    return {
      workflowApuracaoAcao: this.$api.workflowApuracaoAcao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      resourceApuracaoAcaoListagem: this.$api.apuracaoAcaoListagem(this.$resource),
      resourceContrato: this.$api.apuracaoContrato(this.$resource),
      panelKey: 0,
      totalPageApuracao: 0,
      esperar: false,
      recebimentoSemValorNaoEmitido: true,
      dialogJustificativaTipoUso: false,
      apuracaoSelecionada: {},
      modalJustificativaFlag: '',
      isModoEdicaoVlrFornecedor: false,
      prefixRefNameRecDefinido: 'modalRecDefinido-',
      prefixRefNameVBDefinido: 'modalVBDefinido-',
      prefixRefNameTipoUso: 'modalTipoUso-',
      dialogPlanejamentoContrato: false,
      contrato: null,
      acao: null,
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
        valorAjusteNegativo: (value) => value >= 0 || this.$t('errors.valor_negativo'),
        valorPositivo: (value) => (!!value && value > 0) || this.$t('errors.valor.negativo'),
        valorMaiorQueEfetuado: (value, valorRecebido) => (!!value && value >= valorRecebido)
        || this.$t('errors.valor.menor_que_efetuado'),
      },
      headersApuracao: [
        { text: '', value: 'checkbox', sortable: false },
        { text: '', value: 'icon', sortable: false },
        {
          text: this.$tc('label.acao', 2), value: 'acoes', class: 'nowrap', sortable: false,
        },
        {
          text: this.$tc('label.cod_contrato', 1), value: 'idContrato', class: 'nowrap',
        },
        {
          text: this.$tc('label.fornecedor', 1), value: 'origemContrato', class: 'nowrap',
        },
        {
          text: this.$tc('label.cod_apuracao', 1), value: 'idApuracao', class: 'nowrap',
        },
        { text: this.$tc('title.categorias', 1), value: 'categoria', class: 'nowrap' },
        { text: this.$tc('title.produto', 1), value: 'qtdProduto', class: 'nowrap' },
        {
          text: this.$tc('label.periodo_calculado', 1), value: 'periodoVigencia', class: 'nowrap',
        },
        {
          text: this.$tc('label.calculo_apuracao', 1), value: 'calculoApuracao', class: 'nowrap',
        },
        {
          text: this.$tc('label.calculo_bonificacao', 1), value: 'calculoBonificacao', class: 'nowrap',
        },
        {
          text: this.$tc('label.verba', 1), value: 'verba', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_total_nd_gerado', 1), value: 'vlrNdGerado', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.realizado', 1), value: 'vlrRealizado', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_base', 1), value: 'valorBase', class: 'nowrap', cellClass: 'nowrap', align: 'center',
        },
        {
          text: this.$tc('label.ajuste_contratual', 1), width: '10%', value: 'vlrRetroativo', class: 'nowrap', cellClass: 'nowrap', align: 'center',
        },
        {
          text: this.$tc('label.recebimento_definido', 1), value: 'recebimentoDefinido', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.tipo_uso_definido', 1), value: 'tipoUso', width: '10%', class: 'nowrap', cellClass: 'nowrap', sortable: false,
        },
      ],
      apuracaoDetalhes: [],
      expanded: [],
      indJustificativa: false,
      metas: [],
      idsApuracaoDetalhes: [],
      exibeModalRecebimentoParcial: false,
      apuracaoParcial: {},
      tamanhoTela: null,
      isEditandoValorBase: false,
      subTitulo: '',
    };
  },
  methods: {
    getPercent,
    getMoney,
    getNumber,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    ordenacaoAlterada(p1, p2) {
      if ((p1.sortBy.length && !p2.sortBy.length) || (!p1.sortBy.length && p2.sortBy.length)) {
        return true;
      }
      if (p1.sortBy.length && p2.sortBy.length) {
        if (p1.sortBy[0] !== p2.sortBy[0]) {
          return true;
        }
        if (p1.sortBy[0] === p2.sortBy[0] && p1.sortDesc[0] !== p2.sortDesc[0]) {
          return true;
        }
      }
      return false;
    },
    onResize() {
      this.tamanhoTela = window.innerWidth;
    },
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    resetarPagination() {
      this.pagination.page = 1;
      this.resetarExpanded();
    },
    abrirModalIntervaloMeta(item) {
      const idAcao = Number(item.idAcao);
      const datasValidas = this.verificaRetornaDatasValidas(item);
      const {
        calculoApuracao, formaBonificacao, faixaAtingida, idApuracao, existeAtingimento,
      } = { ...item };
      this.$refs.dialogMetaIntervalo.open(idAcao, calculoApuracao, formaBonificacao, faixaAtingida,
        existeAtingimento, datasValidas, this.filtros, idApuracao);
    },
    abrirModalVerbaIntervaloMetaCumulativa(item) {
      const idAcao = Number(item.idAcao);
      const datasValidas = this.verificaRetornaDatasValidas(item);
      const {
        idGrupoFornecedor, idsCategoriasAgrupadas, categoria, acaoTipo, idApuracao, idRecebimentoParcial,
      } = { ...item };
      this.subTitulo = `- ${categoria} - ${acaoTipo}`;
      this.$refs.dialogVerbaIntervaloMetaCumulativa.open(idAcao, idApuracao, idGrupoFornecedor,
        idsCategoriasAgrupadas, idRecebimentoParcial, datasValidas, this.filtros);
    },
    abrirModalIntervaloMetaCumulativa(item) {
      const idAcao = Number(item.idAcao);
      const datasValidas = this.verificaRetornaDatasValidas(item);
      const {
        idGrupoFornecedor, idsCategoriasAgrupadas, categoria, acaoTipo, idApuracao, idRecebimentoParcial,
      } = { ...item };
      this.subTitulo = `- ${categoria} - ${acaoTipo}`;
      this.$refs.dialogIntervaloMetaCumulativa.open(idAcao, idApuracao, idGrupoFornecedor,
        idsCategoriasAgrupadas, idRecebimentoParcial, datasValidas, this.filtros);
    },
    abrirModalCancelamento(item) {
      this.$refs.dialogCancelamento.open(item);
    },
    formatarVerba(item) {
      if (item.formaBonificacao === 'FIXO') {
        return this.getMoney(item.verba);
      }
      return this.getPercent(item.verba);
    },
    formatarVigencia(data) {
      return moment(data, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
    },
    detalharApuracao(item) {
      this.dialogPlanejamentoContrato = true;
      this.contrato = item;
    },
    close() {
      this.dialogPlanejamentoContrato = false;
    },
    isMetaCumulativa(tipoMetaApuracao) {
      return ['CUMULATIVA_COM_META_PARTIDA', 'CUMULATIVA_SEM_META_PARTIDA'].includes(tipoMetaApuracao);
    },
    formatarValorRealizado(valor, calculoApuracao, tipoMetaApuracao) {
      if (valor === 0) {
        return '-';
      }
      if (calculoApuracao === 'SELLIN_VALOR') {
        return this.getMoney(valor);
      }
      if (calculoApuracao === 'SELLIN_PERCENTUAL' || (!calculoApuracao && this.isMetaCumulativa(tipoMetaApuracao))) {
        return this.getPercent(valor);
      }
      return this.getNumber(valor);
    },
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    reRenderizarTable() {
      this.panelKey += 1;
    },
    indEdicaoRealizado(item) {
      return item.indEdicaoRealizado || item.indPreenchimentoValorRealizado;
    },
    indRecebimentoDefinidoAlterado(item) {
      return this.formatarValor(item.recebimentoDefinido) !== this.formatarValor(item.recebimentoAtual) && item.recebimentoDefinidoOriginalValor;
    },
    indValorBaseDefinidoAlterado(item) {
      return this.formatarValor(item.valorBase) !== this.formatarValor(item.valorBaseOriginal) && item.valorBaseAlterado;
    },
    indRealizadoDetalhamento(item) {
      return item.calculoApuracao === 'SELLIN_PERCENTUAL' && item.template === 'CRESCIMENTO';
    },
    indRealizadoDetalhamentoSellinValor(item) {
      return item.calculoApuracao === 'SELLIN_VALOR' && item.template === 'CRESCIMENTO' && item.vlrRealizado > 0;
    },
    indCalculoSelloutVolume(item) {
      return !!(item.calculoBonificacao === 'SELLOUT' || item.calculoApuracao === 'SELLOUT_VOLUME');
    },
    realizadoPorQuantidade(item) {
      return (item.indPreenchimentoValorRealizado && item.template === 'META_N/A')
        || item.template === 'META_POR_QUANTIDADE';
    },
    abrirModalRealizado(idApuracao) {
      this.$refs.dialogRealizadoDetalhamento.open(idApuracao);
    },
    indJustificativaAlterarValor(item) {
      return (item.indJustificativaAlterarValor && item.indJustificativa)
        || item.justificativaAlterarRecebimento;
    },
    indBonificacaoFixo(item) {
      return item.formaBonificacao && item.formaBonificacao === 'FIXO';
    },
    retornarValoresIniciaisApuracao() {
      const apuracao = this.apuracoes.find((a) => a.idApuracao === this.apuracaoSelecionada.idApuracao);
      apuracao.vlrRealizado = this.apuracaoSelecionada.valorRealizadoInicial;
      apuracao.recebimentoDefinido = this.apuracaoSelecionada.recebimentoDefinidoInicial;
      apuracao.valorBase = this.apuracaoSelecionada.valorBaseInicial;
      this.apuracaoSelecionada = {};
    },
    cancelarAlteracaoValor() {
      const nomeComponente = 'valor_recebimento_'.concat(this.apuracaoSelecionada.idApuracao);
      this.retornarValoresIniciaisApuracao();
      this.$emit('desabilitaBotao', false);
      setTimeout(() => {
        const componente = document.getElementById(nomeComponente);
        if (componente !== null && componente !== undefined) {
          componente.focus();
        }
      });
    },
    confirmarAlteracaoValor(justificativa, apuracao = null) {
      this.$emit('desabilitaBotao', false);
      if (apuracao) {
        this.apuracaoSelecionada = apuracao;
        this.apuracaoParcial = apuracao;
      }
      const params = {
        justificativaAlteracaoValorPagamento: justificativa,
        vlrConfApuracao: parseFloat(this.apuracaoSelecionada.recebimentoDefinido),
        valorRealizado: this.apuracaoSelecionada.vlrRealizado,
      };

      this.atualizarValor(params);
    },
    cancelarAlteracaoValorBase() {
      const nomeComponente = 'valor_base_'.concat(this.apuracaoSelecionada.idApuracao);
      this.retornarValoresIniciaisApuracao();
      this.$emit('desabilitaBotao', false);
      setTimeout(() => {
        const componente = document.getElementById(nomeComponente);
        if (componente !== null && componente !== undefined) {
          componente.focus();
        }
      });
    },
    confirmarAlteracaoValorBase(justificativa, apuracao = null) {
      this.$emit('desabilitaBotao', false);
      if (apuracao) {
        this.apuracaoSelecionada = apuracao;
        this.apuracaoParcial = apuracao;
      }
      const params = {
        justificativaAlteracaoValorBase: justificativa,
        valorBase: parseFloat(this.apuracaoSelecionada.valorBase),
      };

      this.atualizarValor(params);
    },
    buscarNdsGeradas(idApuracao) {
      this.resourceContrato.buscarNDsGeradas({ idApuracao }).then((res) => {
        if (res.body) {
          setTimeout(() => this.$refs.dialogNDsGeradas.open(res.body), 2E2);
        }
      });
    },
    alteracaoValorRealizado(item) {
      if (item.vlrRealizado === '' || item.vlrRealizado < 0
        || (item.vlrRealizado === item.valorRealizadoInicial)) {
        return;
      }
      this.apuracaoSelecionada = item;
      const params = {};
      params.valorRealizado = this.apuracaoSelecionada.vlrRealizado;
      if (this.apuracaoSelecionada.indPreenchimentoValorRealizado && this.apuracaoSelecionada.indAgrupamentoCategoriaKpi) {
        params.vlrConfApuracao = this.apuracaoSelecionada.vlrRealizado * this.apuracaoSelecionada.verba;
        if (params.vlrConfApuracao !== item.recebimentoDefinidoInicial) {
          this.apuracaoSelecionada.recebimentoDefinido = params.vlrConfApuracao;
          this.$refs.dialogJustificativa.open();
        } else {
          this.atualizarValor(params);
        }
      } else if (this.apuracaoSelecionada.indEdicaoRealizado) {
        this.buscarValorBonificacaoMeta(this.apuracaoSelecionada.vlrRealizado).then((valorBonificacao) => {
          if (this.apuracaoSelecionada.template === 'META_POR_QUANTIDADE') {
            if (this.apuracaoSelecionada.formaBonificacao === 'PERCENTUAL') {
              params.vlrConfApuracao = ((this.apuracaoSelecionada.vlrRealizado / 100) * valorBonificacao).toFixed(2);
            } else {
              params.vlrConfApuracao = valorBonificacao;
            }
          } else if (this.apuracaoSelecionada.formaBonificacao === 'PERCENTUAL') {
            params.vlrConfApuracao = ((this.apuracaoSelecionada.valorBase / 100) * valorBonificacao).toFixed(2);
          } else {
            params.vlrConfApuracao = valorBonificacao;
          }
          if (params.vlrConfApuracao !== item.recebimentoDefinidoInicial) {
            this.apuracaoSelecionada.recebimentoDefinido = params.vlrConfApuracao;
            this.$refs.dialogJustificativa.open();
          } else {
            this.atualizarValor(params);
          }
        });
      }
    },
    atualizarValor(params = {}) {
      const parametros = params;
      const datas = this.montarDataInicioFimEdicaoValores(this.apuracaoSelecionada);
      parametros.indApuracaoAgrupada = this.apuracaoSelecionada.indAgrupamentoCategoriaKpi;
      parametros.valorRealizadoOriginal = this.apuracaoSelecionada.recebimentoAtual;
      parametros.valorBaseOriginal = this.apuracaoSelecionada.valorBaseOriginal;
      parametros.id = this.apuracaoSelecionada.idApuracao;
      parametros.idContrato = this.apuracaoSelecionada.idContrato;
      parametros.dtaInicio = datas.inicio ? datas.inicio : moment(this.filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD');
      parametros.dtaFim = datas.fim ? datas.fim : formatlastDayOfTheMonth(moment(this.filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      this.apuracaoAcaoResource.atualizar(parametros)
        .then(() => {
          const apuracao = this.apuracaoSelecionada;
          apuracao.valorRealizadoInicial = apuracao.vlrRealizado;
          apuracao.valorBaseInicial = apuracao.valorBase;
          this.buscarFornecedoresApuracao(apuracao.idApuracao).then(() => {
            apuracao.recebimentoDefinidoOriginal = params.vlrConfApuracao;
            apuracao.recebimentoDefinido = params.vlrConfApuracao;
            apuracao.valorBase = params.valorBase;
            apuracao.justificativaAlterarRecebimento = params.justificativaAlteracaoValorPagamento;
            apuracao.justificativaAlterarValorBase = params.justificativaAlteracaoValorBase;
            if (apuracao.valorRecebido) {
              apuracao.recebimentoPendente = apuracao.recebimentoDefinido - apuracao.valorRecebido;
            }
            this.apuracaoSelecionada = apuracao;
            this.apuracaoParcial = apuracao;
            this.indJustificativa = false;
            this.apuracaoSelecionada = {};
            this.$toast(this.$t('message.atualizar_tabela'));
            this.resetarExpanded();
            this.reRenderizarTable();
          });
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
    guardarValorInicial(item) {
      item.valorRealizadoInicial = item.vlrRealizado;
      item.recebimentoDefinidoInicial = item.recebimentoDefinido;
      item.valorBaseInicial = item.valorBase;
    },
    buscarValorBonificacaoMeta(valor) {
      let valorBonificacao = 0;
      return this.resourceApuracaoAcaoListagem.buscarMetas({ idAcao: this.apuracaoSelecionada.idAcao }).then((res) => {
        this.metas.push(...res.data);
        if (Math.max(...this.metas.map((meta) => meta.vlrMetaAte)) < valor) {
          return Math.max(...this.metas.map((meta) => meta.vlrBonificacao));
        }
        const menorMeta = this.metas.find((meta) => meta.vlrMetaDe === 0);
        if (!!menorMeta && valor <= 0) {
          return menorMeta.vlrBonificacao;
        }
        this.metas.forEach((meta) => {
          if (numberBetweenValues(valor, meta.vlrMetaDe, meta.vlrMetaAte)) {
            valorBonificacao = meta.vlrBonificacao;
          }
        });
        return valorBonificacao;
      });
    },
    buscarFornecedoresApuracao(idApuracao, statusApuracao = null) {
      const filtrosAplicados = {
        cod_apuracao: idApuracao,
        data_inicio_apuracao: moment(this.filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD'),
        data_fim_apuracao: formatlastDayOfTheMonth(moment(this.filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
      };
      return buscarDetalhesApuracaoContrato({ ...filtrosAplicados }, this.$resource)
        .then((response) => {
          const apuracao = this.apuracaoSelecionada;
          apuracao.fornecedores = response.data;
          apuracao.fornecedores.forEach((e) => {
            e.statusApuracao = statusApuracao;
          });
          this.atualizarFornecedoresSelecionados(idApuracao, response.data);
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },

    atualizarFornecedoresSelecionados(idApuracao, fornecedores) {
      const copiaFornecedor = copyObject(fornecedores);
      this.apuracoes.forEach((a) => {
        if (a.idApuracao === idApuracao) {
          a.fornecedores = copiaFornecedor;
        }
      });
    },

    salvarValorAlterado(item, salvarValorBase) {
      this.apuracaoSelecionada = item;
      if (item.recebimentoDefinido === item.recebimentoAtual) {
        item.justificativaAlterarRecebimento = '';
      }
      if ((item.valorBase === item.valorBaseOriginal) || !salvarValorBase) {
        item.justificativaAlterarValorBase = '';
      }
      const params = {
        idApuracao: item.idApuracao,
        vlrAnterior: salvarValorBase ? parseFloat(item.valorBaseOriginal) : parseFloat(item.recebimentoAtual),
        vlrAlterado: salvarValorBase ? parseFloat(item.valorBase) : parseFloat(item.recebimentoDefinido),
        justificativa: salvarValorBase ? item.justificativaAlterarValorBase : item.justificativaAlterarRecebimento,
      };

      this.apuracaoParcial = item;

      if (salvarValorBase) {
        this.apuracaoParcial.objValorBaseAlterado = params;
      } else {
        this.apuracaoParcial.objRecebimentoDefinidoAlterado = params;
      }

      this.indJustificativa = false;
      this.apuracaoSelecionada = {};
      this.$toast(this.$t('message.atualizar_tabela'));
      this.reRenderizarTable();
      this.isEditandoValorBase = false;
      this.alterarIsEditandoValorBase(this.isEditandoValorBase);
    },

    salvarTipoUsoIndividual(item) {
      this.apuracaoSelecionada = item;

      const params = {
        id_tipo_uso: item.idTipoUsoNovo,
        justificativa_tipo_uso: item.justificativaTipoUso,
      };

      item.objTipoUsoAlterado = params;

      const tipoUsoNovo = this.listaTiposUso.find((el) => el.id === item.idTipoUsoNovo);
      this.apuracaoSelecionada.tipoUsoNovo = tipoUsoNovo.nome;
      this.apuracaoSelecionada.tipoUso = tipoUsoNovo.nome;

      this.apuracaoSelecionada = {};

      this.$toast(this.$t('message.atualizar_tabela'));
      this.reRenderizarTable();
    },
    resetarExpanded() {
      this.expanded = [];
      this.idsApuracaoDetalhes = [];
    },
    verificarApuracaoExpandida(apuracoes) {
      if (!apuracoes.length) {
        return;
      }
      const idsNaoBuscados = apuracoes.filter((e) => !this.idsApuracaoDetalhes.includes(e.idApuracao));
      if (idsNaoBuscados && idsNaoBuscados.length) {
        const { idApuracao, statusApuracao } = idsNaoBuscados[0];
        if (idApuracao) {
          this.apuracaoSelecionada = apuracoes;
          this.idsApuracaoDetalhes.push(idApuracao);
          this.buscarFornecedoresApuracao(idApuracao, statusApuracao);
        }
      }
    },
    confirmarCancelarApuracao(item, justificativaCancelamento) {
      const apuracao = item;
      const { idApuracao } = apuracao;
      const param = {
        idApuracao,
        justificativa: justificativaCancelamento,
      };
      cancelarApuracao(param, this.$resource)
        .then(() => {
          const index = this.apuracoes.findIndex((a) => a.idApuracao === idApuracao);
          this.apuracoes.splice(index, 1);
          this.$toast(this.$t('message.apuracao_cancelada_sucesso'));
          this.apuracaoSelecionada = {};
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    visualizarPeriodoCalculado(apuracao) {
      setTimeout(() => this.$refs.ModalVisualizarPeriodoCalculado.open(apuracao), 2E2);
    },
    verificarQuantidadeRegristrosExportacao(item) {
      const params = {
        idAcao: Number(item.idAcao),
        idApuracao: Number(item.idApuracao),
        tipoNota: 'SELLOUT_VOLUME',
        id_recebimento_parcial: item.idRecebimentoParcial || null,
      };

      verificarDadosExportacao(params, this.$resource).then((response) => {
        if (response.body) {
          if (response.body > 400000) {
            this.executarExportacaoAssincrona(params);
          } else {
            this.exportarNotas(item, true);
          }
        } else {
          this.$toast(this.$t('message.sem_dados_exportacao_periodo'));
        }
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    executarExportacaoAssincrona(params) {
      executarExportacao(params, this.$resource)
        .then(() => {
          this.$toast(this.$t('message.exportacao_em_andamento_notificacao_email'));
        }, (err) => {
          this.$error(this, err);
        });
    },
    exportarNotas(item, exportacaoRealizado) {
      if (!item.possuiNota) {
        this.$toast(this.$t('message.sem_dados_notas_fiscais_apuracao'));
        return;
      }
      const params = {
        id_acao: Number(item.idAcao),
        id_apuracao: Number(item.idApuracao),
      };
      if (item.idRecebimentoParcial) {
        params.id_recebimento_parcial = item.idRecebimentoParcial;
      }
      if (this.filtros.dataInicio && this.filtros.dataFim) {
        params.dta_inicio_apuracao = moment(this.filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD');
        params.dta_fim_apuracao = formatlastDayOfTheMonth(moment(this.filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      }
      if (this.indCalculoSelloutVolume(item) && exportacaoRealizado) {
        exportacao.exportar(null, 'notas_sellout_memoria_apuracao_acao_contrato', this, params);
      } else {
        exportacao.exportar(null, 'notas_sellin_memoria_apuracao_acao', this, params);
      }
    },
    visualizarListaProdutos(item) {
      this.acao = item;
      if (this.acao.idsProdutos && this.acao.idsProdutos.length) {
        this.acao.idsProdutos = this.acao.idsProdutos.split(',').map(Number);
      }
      setTimeout(() => {
        this.$refs.modalSelecaoProduto.open();
      }, 350);
    },
    alterarIsEditandoValorBase(valor) {
      this.isEditandoValorBase = valor;
      this.$emit('ApuracaoContratoTabData__isEditandoValorBase', valor);
    },
  },
};
