<template>
  <div>
    <v-container fluid grid-list-md text-xs-center id="liquidacao-totalizadores">
      <v-row align="center" justify="center" fill-height>
        <v-col cols="12" class="tamanho-cards" v-for="card in totalizadoresOrdenados" :key="card.titulo">
          <v-card :style="{'background-color': `${card.cor}`}">
            <v-card-title class="justify-center title white--text pt-0">
              {{ $tc(card.titulo, 1) }}
            </v-card-title>
            <v-card-text>
              <v-row class="headline white--text">
                <v-col cols="12" class="text-center pa-0">{{ getMoney(card.valor_monetario) }}</v-col>
                <v-col cols="12" class="text-center pa-0">{{ getPercent(card.valor_percentual) }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
  getPercent,
} from '@/common/functions/helpers';
import { buscarTotalizadores } from '@/common/resources/planejamento-contrato-listagem';

export default {
  components: {
  },
  data() {
    return {
      lista: [],
    };
  },
  computed: {
    totalizadoresOrdenados() {
      function compare(a, b) {
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        return 0;
      }

      return this.lista.sort(compare);
    },
  },
  methods: {
    getMoney,
    getPercent,
    aplicaFiltros(filtros) {
      const params = {
        ...filtros,
      };

      buscarTotalizadores(params, this.$resource)
        .then((response) => {
          this.lista = response.data.resposta;
          if (this.lista.length !== 3) {
            this.tratarCards();
          }
          this.tratarCores();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },

    tratarCores() {
      this.lista.forEach((item) => {
        if (item.status === 'RECEBIMENTO') {
          item.cor = 'rgb(26, 188, 156)';
          item.ordenacao = 1;
        } else if (item.status === 'PAGAMENTO') {
          item.cor = 'rgb(26, 188, 156)';
          item.ordenacao = 1;
        } else if (item.status === 'PENDENTE_APURACAO') {
          item.cor = 'rgb(230, 126, 34)';
          item.ordenacao = 2;
        } else if (item.status === 'REJEITADA') {
          item.cor = 'rgb(231, 76, 60)';
          item.ordenacao = 3;
        }
      });
    },

    tratarCards() {
      this.tratarCardsLista([
        { status: 'RECEBIMENTO', titulo: this.$tc('label.recebimento', 1) },
        { status: 'PENDENTE_APURACAO', titulo: this.$tc('label.pendente_apuracao', 1) },
        { status: 'REJEITADA', titulo: this.$tc('label.apuracao_rejeitada', 1) },
      ]);
    },
    tratarCardsLista(listaCardsSemDados) {
      this.lista.forEach((item) => {
        if (item.status === 'RECEBIMENTO') {
          listaCardsSemDados.splice(listaCardsSemDados.findIndex((card) => card.status === 'RECEBIMENTO'), 1);
        } else if (item.status === 'PAGAMENTO') {
          listaCardsSemDados.splice(listaCardsSemDados.findIndex((card) => card.status === 'PAGAMENTO'), 1);
        } else if (item.status === 'PENDENTE_APURACAO') {
          listaCardsSemDados.splice(listaCardsSemDados.findIndex((card) => card.status === 'PENDENTE_APURACAO'), 1);
        } else if (item.status === 'REJEITADA') {
          listaCardsSemDados.splice(listaCardsSemDados.findIndex((card) => card.status === 'REJEITADA'), 1);
        }
      });

      if (listaCardsSemDados.length) {
        listaCardsSemDados.forEach((item) => {
          this.lista.push({
            titulo: item.titulo,
            status: item.status,
            valor_monetario: 0,
            valor_percentual: 0,
          });
        });
      }
    },
  },
};
</script>

<style>
  #liquidacao-totalizadores .tamanho-cards {
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
    padding: 12px;
  }

  @media screen and (max-width: 1420px) {
    #liquidacao-totalizadores .tamanho-cards {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      width: 100%;
      padding: 12px;
    }
  }

  @media screen and (max-width: 1060px) {
    #liquidacao-totalizadores .tamanho-cards {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      padding: 12px;
    }
  }
</style>
