<template>
  <v-menu
    ref="tipo_uso_massa"
    v-if="podeAlterarTipoUso"
    :close-on-content-click="false"
    :nudge-width="400"
    v-model="menuAteraTipoUso"
    transition="scale-transition"
    offset-x
    left
    class="mr-3">
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-on="{ ...tooltip, ...menu }"
            class="ml-8"
            id="btn_tipo_uso"
            color="primary"
            :disabled="!podeAlterarTipoUso">
            {{ $t('label.alterar_tipo_uso') }}
          </v-btn>
        </template>
        <span>{{ $t('message.definir_todos_tipo_uso') }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-form ref="form" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                id="tipo-uso"
                class="custom-autocomplete"
                v-model="tipoUso.id"
                :rules="[rules.required]"
                :items="listaTiposUso"
                :label="`${$tc('label.tipo_uso', 1)} *`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nome"
                item-value="id"
                required
                :clearable="true"
              ></v-autocomplete>
              <v-textarea
                id="tipouso_justificativa"
                index="1"
                ref="textareaJustificativa"
                name="tipouso_justificativa"
                v-model="tipoUso.justificativaTipoUso"
                auto-grow
                rows="1"
                :rules="[rules.required]"
                :label="`${$t('label.justificativa')} *`"
                :counter="500"
                maxlength="500">
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancelarAlteracaoTipoUso">{{ $t('label.cancelar') }}</v-btn>
        <v-btn
            @click.native="aplicarAlteracaoTipoUso"
            color="primary"
            :disabled="!existeApuracoesSelecionadas">{{ $t('label.aplicar') }}</v-btn>
        </v-card-actions>
        <div class="pa-1" style="text-align: end;">
        <span v-if="!existeApuracoesSelecionadas" style="color: red">{{ $t('message.selecione_uma_apuracao') }}</span>
        </div>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  buscaAutocomplete,
  triggerSelecao,
} from '@/common/functions/autocomplete-utils';

export default {
  name: 'ApuracaoContratoModalTipoUsoMassivo',
  props: {
    listaTiposUso: {
      type: Array,
      default: () => ([]),
    },
    apuracoesSelecionadas: {
      type: Array,
      default: () => ([]),
    },
    existeApuracoesSelecionadas: Boolean,
    buscarTiposUso: Function,
  },
  data() {
    return {
      workflowApuracaoAcao: this.$api.workflowApuracaoAcao(this.$resource),
      menuAteraTipoUso: false,
      tipoUso: {},
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    podeAlterarTipoUso() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_ALTERA_TIPO_USO').length;
    },
    cancelarAlteracaoTipoUso() {
      this.menuAteraTipoUso = false;
      this.resetarTipoUso();
    },
    resetarTipoUso() {
      this.tipoUso = {
        nomTipoUso: '',
        justificativaTipoUso: '',
      };
    },
    aplicarAlteracaoTipoUso() {
      this.menuAteraTipoUso = false;
      this.apuracoesSelecionadas
        .forEach((apuracao) => {
          this.alterarTipoUsoNovo(apuracao, this.tipoUso);
        });
      this.salvarTipoUsoEmLote()
        .then(() => {
          this.$toast(this.$t('message.tipo_uso_alterado_sucesso'));
          this.atualizaTable();
          this.resetarTipoUso();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarTipoUsoEmLote() {
      const promises = [];
      this.apuracoesSelecionadas
        .filter((a) => a.selecionado)
        .forEach((apuracao) => {
          const { idApuracao } = apuracao;
          const promise = this.workflowApuracaoAcao.salvarApuracaoAcaoContratoTipoUso({ idApuracao },
            {
              tipo_uso: this.tipoUso.id,
              justificativa_tipo_uso: this.tipoUso.justificativaTipoUso,
            })
            .catch((err) => {
              this.$error(this, err);
            });
          promises.push(promise);
        });
      return Promise.all(promises);
    },
    alterarTipoUsoNovo(apuracao, tipoUso = null) {
      if (apuracao && apuracao.selecionado && tipoUso != null) {
        apuracao.idTipoUsoNovo = tipoUso.id;
        apuracao.justificativa = tipoUso.justificativaTipoUso;
      }
    },
    atualizaTable() {
      this.$emit('ApuracaoContratoModalTipoUsoMassivo__atualizaTabela', this.tipoUso.id);
    },
  },
};
</script>
